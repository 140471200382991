const extractName = (fullName) => {
  if (!fullName) return "";
  const parts = fullName.split(" ");
  return parts.length > 2
    ? `${parts[1]} ${parts[0]}`
    : `${parts[0]} ${parts[1]}`;
};

export const getInitials = (name) => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName?.charAt(0)}${lastName?.charAt(0)}`;
};

const colors = [
  "#f56a00",
  "#7265e6",
  "#ffbf00",
  "#00a2ae",
  "#e94e77",
  "#542437",
  "#53777a",
  "#f28d35",
  "#7d3c98",
  "#5dade2",
  "#117a65",
  "#ec7063",
  "#a569bd",
  "#48c9b0",
  "#f5b041",
  "#d35400",
];

export const getColorFromInitials = (initials) => {
  let hash = 0;
  for (let i = 0; i < initials.length; i++) {
    hash = initials.charCodeAt(i) + ((hash << 5) - hash);
  }
  const colorIndex = Math.abs(hash % colors.length);
  return colors[colorIndex];
};

export const getUserData = (row, language) =>
  row.userData[language]?.name
    ? row.userData[language]
    : row.comment
      ? { name: row.comment }
      : row.userData.ka;

export const convertTableToVertical = ({ dataSource, columns, size }) => {
  return {
    showHeader: size !== "xs",
    columns:
      size === "xs"
        ? [
            { title: "title", dataIndex: "title" },
            { title: "value", dataIndex: "value" },
          ]
        : columns,
    dataSource:
      size === "xs"
        ? dataSource?.reduce((acc, record) => {
            acc.push(
              columns.map((column) => ({
                title: column.title,
                value:
                  column?.render(record[column.dataIndex], record) ||
                  record[column.dataIndex],
              })),
            );
            return acc;
          }, [])
        : [dataSource],
  };
};

export default extractName;
