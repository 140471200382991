import React from "react";
import { Table, Divider } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import useData from "../../hooks/useData";
import { convertTableToVertical } from "../../utils/extractName";
import "sweetalert2/dist/sweetalert2.min.css";
import useWindowSize from "../../hooks/useWindowSize";
const Employees = () => {
  const { data: list } = useData("/products/users");
  // const { data: designers } = useData("/products/designers");

  const { width } = useWindowSize();
  const { i18n } = useTranslation();

  const { language } = i18n;
  dayjs.locale(language);

  const getName = (id) => {
    return list?.find((user) => user._id === id)?.name;
  };

  const tableColumns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (text, record) => getName(record._id),
    },
    {
      title: "Эл. почта",
      dataIndex: "email",
      key: "email",
      render: (text) => text,
    },
    {
      title: "Телефон",
      dataIndex: "mobile",
      key: "phone",
      render: (text) => <a href={`tel:+995${text}`}>{text}</a> || "-",
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      render: (text) => text,
    },
    {
      title: "Дата рождения",
      dataIndex: "birthDate",
      key: "birtDate",
      render: (text) => dayjs(text).format("DD MMMM YYYY"),
    },
    {
      title: "День рождения",
      dataIndex: "birthDate",
      key: "birthDate",
      render: (date) => {
        const birthdayDate = dayjs(date); // Используем вашу дату в ISO формате
        const today = dayjs();

        // Определяем дату следующего дня рождения в текущем году
        const thisYearBirthday = birthdayDate.year(today.year());

        // Если день рождения уже прошёл в этом году, устанавливаем дату на следующий год
        const nextBirthday = thisYearBirthday.isBefore(today, "day")
          ? thisYearBirthday.add(1, "year")
          : thisYearBirthday;

        // Вычисляем разницу в месяцах и днях
        let monthsUntilBirthday = nextBirthday.diff(today, "month");
        const daysUntilBirthday = nextBirthday.diff(
          today.add(monthsUntilBirthday, "month"),
          "day",
        );

        // Возвращаем либо месяцы, либо дни
        if (monthsUntilBirthday > 0) {
          return `${monthsUntilBirthday} месяцев`;
        } else {
          return `${daysUntilBirthday} дней`;
        }
      },
    },
    {
      title: "Возраст",
      dataIndex: "birthDate",
      key: "age",
      render: (date) => {
        const birthdayDate = dayjs(date);
        const today = dayjs();
        return today.diff(birthdayDate, "year");
      },
    },
  ].filter(Boolean);

  const dataSource = list;

  const data = convertTableToVertical({
    columns: tableColumns,
    dataSource,
    size: width < 1024 ? "xs" : undefined,
  });

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          {list && (
            <div className="table-responsive">
              {data?.dataSource?.map((record, index) => (
                <React.Fragment key={index}>
                  <Table
                    columns={data.columns}
                    dataSource={record}
                    pagination={record?.length > 20}
                    scroll={{ x: "max-content" }}
                    showHeader={width >= 1024}
                  />
                  <Divider />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Employees;
