import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Image, Table, Descriptions } from "antd";

import useData from "../../../hooks/useData";
import { useTranslation } from "react-i18next";
import { createURL } from "../../../utils/createURL";
import NumberFormat from "../../../utils/NumberFormat";

// Function to parse the 'name' and 'title' fields from JSON strings
// const parseJSONField = (field, lang = "en") => {
//   try {
//     const parsed = JSON.parse(field);
//     return parsed[lang] || parsed["en"];
//   } catch (e) {
//     return field;
//   }
// };

const ViewData = () => {
  const { destination, category } = useParams();
  const { data: destinations } = useData("/web/data/destinations", []);
  const { data: categories } = useData("/web/data/categories", []);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [data, setData] = useState([]);
  const [sortOrder, setSortOrder] = useState({});
  const {
    i18n: { language },
  } = useTranslation();

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record) => {
        return (
          <div
            className="product-info"
            style={{ display: "flex", alignItems: "center", gap: 12 }}
          >
            <Image.PreviewGroup items={record.images}>
              <Image
                src={record.images?.[0]}
                alt="img"
                className="img-fluid img-40"
                width={60}
                height={60}
                style={{
                  background: "#FFF",
                }}
              />
            </Image.PreviewGroup>
            <div>
              <a
                href={`https://www.presta.ge/${language !== "ka" ? `${language}/` : ""}${createURL(getDestinationName(record.destination))}/${createURL(getCategoryName(record.category))}/${createURL(record.path[language])}`}
                target={"_blank"}
                rel={"noreferrer"}
                style={{
                  fontSize: 18,
                  color: "#24a2f6",
                  textDecoration: "underline",
                }}
              >
                {text[language]}
              </a>
            </div>
          </div>
        );
      },
    },
    {
      title: "Заголовок",
      dataIndex: "title",
      key: "title",
      sorter: true,
      render: (text) => <span>{text[language]}</span>,
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      sorter: true,
      render: (text) => <span>{text[language]}</span>,
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      sorter: true,
      width: 140,
      render: (text) => {
        const price = Number(text);
        return (
          <span style={{ fontSize: 18 }}>
            {NumberFormat(price + (price * 50) / 100)} ₾
          </span>
        );
      },
    },
    {
      title: "Размеры",
      dataIndex: "dimension",
      key: "dimension",
      render: (text) => {
        return (
          <Descriptions
            items={[
              {
                label: "Ширина",
                children: `${text?.width} mm` || "N/A",
              },
              {
                label: "Высота",
                children: `${text?.height} mm` || "N/A",
              },
              {
                label: "Глубина",
                children: `${text?.depth} mm` || "N/A",
              },
            ]}
            column={1}
            style={{ width: 140 }}
            size={"small"}
          />
        );
      },
    },
  ];

  const queryParams = useMemo(
    () => ({
      page: pagination.current,
      pageSize: pagination.pageSize,
      sortField: sortOrder.field,
      sortOrder: sortOrder.order === "ascend" ? "asc" : "desc",
      destination,
      category,
    }),
    [pagination, sortOrder.field, sortOrder.order, destination, category],
  );

  const { data: page, isLoading: loading } = useData(
    "/web/products?" + new URLSearchParams(queryParams).toString(),
  );

  useEffect(() => {
    if (page) {
      setPagination(page.pagination);
      // console.log(page.data);
      setData(page.data);
    }
  }, [page]);

  const getDestinationName = useCallback(
    (id) => {
      if (destinations && id) {
        return destinations.find((v) => v._id === id)[`name_${language}`];
      }
      return "N/A";
    },
    [destinations, language],
  );

  const getCategoryName = useCallback(
    (id) => {
      if (categories && id) {
        return categories?.find((v) => v._id === id)?.[`name_${language}`];
      }
      return "N/A";
    },
    [categories, language],
  );

  // const fetchTableData = async (pagination, sortOrder, filters) => {
  //   setLoading(true);
  //
  //   // Construct query params
  //   const queryParams = {
  //     page: pagination.current,
  //     pageSize: pagination.pageSize,
  //     sortField: sortOrder.field,
  //     sortOrder: sortOrder.order === "ascend" ? "asc" : "desc",
  //     storage: filters.storage?.[0], // If filtering by storage (single select)
  //     destination,
  //     category,
  //   };
  //
  //   try {
  //     const response = await Api.get("/web/products", {
  //       params: queryParams,
  //     });
  //
  //     // Map the data according to your structure
  //     const mappedData = response.data.map((item) => ({
  //       key: item._id.$oid,
  //       name: parseJSONField(item.name),
  //       title: parseJSONField(item.title),
  //       price: item.price,
  //       storage: item.storage ? "Available" : "Out of stock",
  //       year: item.year,
  //       // dimensions: `${item.dimension.width}x${item.dimension.height}x${item.dimension.depth}`,
  //     }));
  //
  //     setData(mappedData);
  //     setPagination({
  //       ...pagination,
  //       total: response.totalCount, // Assuming the API response includes a total count
  //     });
  //   } catch (error) {
  //     console.error("Failed to fetch data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  //
  // useEffect(() => {
  //   fetchTableData(pagination, sortOrder, filters);
  // }, [destination, sortOrder, pagination, filters, category]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination(pagination);
    setSortOrder({
      field: sorter.field,
      order: sorter.order,
    });
  };

  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <Table
            columns={columns}
            dataSource={data}
            pagination={pagination}
            loading={loading}
            onChange={handleTableChange}
            size="large"
          />
          {/*<div>{JSON.stringify(params)}</div>*/}
        </div>
      </div>
    </div>
  );
};

export default ViewData;
