import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { User, UserCheck } from "feather-icons-react/build/IconComponents";
import Chart from "react-apexcharts";
import ImageWithBasePath from "../../core/img/imagewithbasebath";
import useData from "../../hooks/useData";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";
import { useTranslation } from "react-i18next";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { GiCheckMark } from "react-icons/gi";
import { Clock } from "react-feather";
import { Flex, Table, Divider } from "antd";
import addBusinessDays from "../../utils/addBusinessDays";
import NumberFormat from "../../utils/NumberFormat";
import SelectUser from "../../Components/SelectUser/SelectUser";
import extractName, {
  convertTableToVertical,
  getUserData,
} from "../../utils/extractName";
import useWindowSize from "../../hooks/useWindowSize";
import DashboardCalendar from "./DashboardCalendar";

const Dashboard = () => {
  const { width } = useWindowSize();

  const { permissions, _id } = useAuthUser();
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  dayjs.locale(language);
  const { data: invoices } = useData("/sales/contracts", []);
  const { data } = useData("/dashboard", {
    month: [{ totalPrice: 0, totalServices: 0 }],
  });
  const { data: designers } = useData("/products/designers");

  const { data: teams } = useData("/products/users");

  const [chartOptions, setChartOptions] = useState();

  const workWithMe = invoices?.filter((invoice) => invoice.team?.includes(_id));
  const doneByMe = workWithMe?.filter(
    (invoice) => invoice.status === "completed",
  );

  const getName = (id) => {
    return teams?.find((user) => user._id === id)?.name;
  };

  useEffect(() => {
    setChartOptions({
      series: [
        {
          name: "Контракты",
          data: data.month?.map((m) => m.totalPrice),
        },
      ],
      colors: ["#fec639"],
      chart: {
        type: "bar",
        height: 320,
        stacked: true,
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 280,
          options: {
            legend: {
              position: "bottom",
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          borderRadiusApplication: "end", // "around" / "end"
          borderRadiusWhenStacked: "all", // "all"/"last"
          columnWidth: "20%",
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        min: -200,
        max: data.month.reduce((acc, item) => {
          if (item.totalPrice > acc) {
            acc = item.totalPrice;
          }
          return acc;
        }, 0),
        tickAmount: 5,
      },
      xaxis: {
        categories: data.month.map((m) => dayjs(m.month).format("MMM YYYY")),
      },
      legend: { show: false },
      fill: {
        opacity: 1,
      },
    });
  }, [data]);

  const activeTasks = workWithMe?.filter(
    (invoice) => invoice.status !== "completed",
  );

  const columns = [
    {
      title: t("sales.agreement"),
      dataIndex: "id",
      render: (id) => id,
      fixed: "left",
    },
    {
      title: t("sales.client"),
      dataIndex: "userData",
      render: (userData, row) => getUserData(row, language).name,
      filters: invoices?.reduce((acc, row) => {
        const userData = getUserData(row, language);
        if (!acc.find((f) => f.text === userData.name)) {
          acc.push({ text: userData.name, value: userData.name });
        }
        return acc;
      }, []),
      filterSearch: true,
      onFilter: (value, record) =>
        getUserData(record, language).name.startsWith(value),
      // fixed: "left",
    },
    {
      title: "Срок сдачи",
      dataIndex: "days",
      key: "days",
      render: (days, record) => {
        const startDate = dayjs(new Date(record.created));
        return addBusinessDays(startDate, days).format("D MMM");
      },
    },
    {
      title: "Последнее обновление",
      dataIndex: "history",
      render: (history) => {
        return history?.length
          ? dayjs(history[history?.length - 1].date).format("D MMM HH:mm")
          : "нет";
      },
    },
    {
      title: "Дата подписание договора",
      dataIndex: "date",
      key: "date",
      render: (date) => dayjs(date).format("D MMM"),
    },
    {
      title: "Начало работы",
      dataIndex: "history",
      key: "start",
      render: (history) =>
        history.length
          ? dayjs(history.find((h) => h.status === "cutting").date).format(
              "D MMM",
            )
          : "нет",
    },
    {
      title: "Статус",
      dataIndex: "status",
      render: (status) => t("sales.contractStatus." + status),
    },
    {
      title: t("sales.team_salary"),
      dataIndex: "price",
      render: (price) => `${NumberFormat(Math.floor(price / 1.18) * 0.225)} ₾`,
    },
    {
      title: t("sales.team"),
      dataIndex: "team",
      render: (team) => {
        const members = teams?.filter((user) => user.role === "team_member");
        return (
          <>
            <SelectUser
              multiple
              selected={team}
              disabled={true}
              options={members?.map((team) => ({
                label: team.name,
                value: team._id,
              }))}
              onChange={() => {}}
            />
          </>
        );
      },
      filters: teams
        ?.filter((user) => user.role === "team_member")
        .map((team) => ({
          text: extractName(team.name),
          value: team._id,
        })),
      filterSearch: true,
      onFilter: (value, record) => record.team?.includes(value),
    },
    {
      title: t("sales.designer"),
      dataIndex: "designer",
      render: (designer) => {
        const designerList = designers?.map((user) => ({
          ...user,
          name: getName(user.id),
        }));
        return (
          <SelectUser
            selected={designer}
            disabled={true}
            options={designerList?.map((team) => ({
              label: extractName(team.name),
              value: team._id,
            }))}
            onChange={() => {}}
          />
        );
      },
    },
  ];

  const tableData = convertTableToVertical({
    columns,
    dataSource: activeTasks,
    size: width < 1024 ? "xs" : undefined,
  });

  const events = workWithMe
    .map((order) => {
      const data = {
        title: `Заказ ${order.id} - ${getUserData(order, language).name}`,
        start: dayjs(order.created).toDate(),
        end: addBusinessDays(dayjs(order.created), order.days || 15).toDate(),
        created: dayjs(order.created).toDate(),
        className: order.status === "completed" ? "bg-success" : "bg-secondary",
      };

      return [data];
    })
    .flat();

  return (
    <div>
      <div className="page-wrapper">
        {permissions.length === 2 && permissions.includes("WORK") && (
          <div className="content">
            <div className="row">
              <div className="col-xl-12 col-sm-12 col-12 mb-3">
                <h2>Календарь статусов заказов</h2>
                <DashboardCalendar events={events} />
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className={"dash-widget dash2 w-100"}>
                  <div className="dash-widgetimg">
                    <span>
                      <Clock />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp
                        end={workWithMe?.length - doneByMe?.length || 0}
                        duration={3}
                      />
                    </h5>
                    <h6>В процессе</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className={"dash-widget dash2 w-100"}>
                  <div className="dash-widgetimg">
                    <span>₾</span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp
                        end={
                          workWithMe?.reduce((acc, item) => {
                            if (item?.status !== "completed") {
                              return acc + item.total / 1.18;
                            }
                            return acc;
                          }, 0) || 0
                        }
                        duration={3}
                      />
                    </h5>
                    <h6>В процессе на сумму</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className={"dash-widget dash1 w-100"}>
                  <div className="dash-widgetimg">
                    <span>
                      <GiCheckMark color={"green"} />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp end={doneByMe?.length || 0} duration={3} />
                    </h5>
                    <h6>Установлено заказов</h6>
                  </div>
                </div>
              </div>
              <div className={"col-xl-3 col-sm-6 col-12 d-flex"}>
                <div className={"dash-widget dash1 w-100"}>
                  <div className="dash-widgetimg">
                    <span>₾</span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp
                        end={
                          workWithMe?.reduce((acc, item) => {
                            if (item?.status === "completed") {
                              return acc + item.total / 1.18;
                            }
                            return acc;
                          }, 0) || 0
                        }
                        duration={3}
                      />
                    </h5>
                    <h6>Установлено на сумму</h6>
                  </div>
                </div>
              </div>
              <div className={"col-xl-12 col-sm-12 col-12"}>
                <Flex vertical>
                  <h4>Активные задачи</h4>
                  <div className={"table-responsive"}>
                    <>
                      {tableData?.dataSource?.map((record, index) => (
                        <React.Fragment key={index}>
                          <Table
                            columns={tableData.columns}
                            dataSource={record}
                            pagination={record?.length > 20}
                            scroll={{ x: "max-content" }}
                            showHeader={record?.length === 1}
                          />
                          <Divider />
                        </React.Fragment>
                      ))}
                    </>
                  </div>
                </Flex>
              </div>
            </div>
          </div>
        )}
        {permissions.includes("ADMIN") && (
          <div className="content">
            <div className="row">
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-widget w-100">
                  <div className="dash-widgetimg">
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/dash1.svg"
                        alt="img"
                      />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp
                        start={0}
                        end={data.totalServices}
                        duration={3}
                      />
                    </h5>
                    <h6>Всего услуг предоставлено</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-widget dash1 w-100">
                  <div className="dash-widgetimg">
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/dash2.svg"
                        alt="img"
                      />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp
                        start={0}
                        end={data.totalPrice}
                        duration={3}
                        prefix="₾"
                      />
                    </h5>
                    <h6>Продаж за весь период</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-widget dash2 w-100">
                  <div className="dash-widgetimg">
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/dash3.svg"
                        alt="img"
                      />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp
                        start={0}
                        end={data.previous}
                        duration={3} // Duration in seconds
                        decimals={1}
                        prefix="₾"
                      />
                    </h5>
                    <h6>Прошлый месяц (оформлено)</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-widget dash3 w-100">
                  <div className="dash-widgetimg">
                    <span>
                      <ImageWithBasePath
                        src="assets/img/icons/dash4.svg"
                        alt="img"
                      />
                    </span>
                  </div>
                  <div className="dash-widgetcontent">
                    <h5>
                      <CountUp
                        start={0}
                        end={data.current}
                        duration={3} // Duration in seconds
                        decimals={1}
                        prefix="₾"
                      />
                    </h5>
                    <h6>Текующий месяц (оформлено)</h6>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count">
                  <div className="dash-counts">
                    <h4>{data.users}</h4>
                    <h5>Коллег</h5>
                  </div>
                  <div className="dash-imgs">
                    <User />
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-sm-6 col-12 d-flex">
                <div className="dash-count das1">
                  <div className="dash-counts">
                    <h4>{data.stores}</h4>
                    <h5>Филиалов</h5>
                  </div>
                  <div className="dash-imgs">
                    <UserCheck />
                  </div>
                </div>
              </div>
            </div>
            {/* Button trigger modal */}

            <div className="row">
              <div className="col-xl-6 col-sm-12 col-12 d-flex">
                <div className="card flex-fill">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h5 className="card-title mb-0">Контракты</h5>
                  </div>
                  <div className="card-body" height={320}>
                    <div id="sales_charts" />
                    {chartOptions && (
                      <Chart
                        options={chartOptions}
                        series={chartOptions.series}
                        type="line"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
