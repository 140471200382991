import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
const Planner = () => {
  const { height, width } = useWindowSize();
  const {
    i18n: { language },
  } = useTranslation();
  return (
    <div>
      <div className="page-wrapper">
        <div className="content">
          <iframe
            onContextMenu={(e) => e.preventDefault()}
            key={`height.${height}.${language}`}
            src={`https://planplace.ru/clients/24036338/?t=${height}&locale=${language}`}
            width="100%"
            height={height - (width < 992 ? 60 : 100)}
            style={{
              minHeight: 450,
              height: height - (width < 992 ? 60 : 100),
              border: 0,
            }}
            frameBorder={0}
            allowFullScreen
          />
        </div>
      </div>
    </div>
  );
};

export default Planner;
