import FullCalendar from "@fullcalendar/react";
import ru from "@fullcalendar/core/locales/ru";
import ka from "@fullcalendar/core/locales/ka";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { Button, Modal } from "antd";

import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useData from "../../hooks/useData";
import addBusinessDays from "../../utils/addBusinessDays";

const generateWorkingDays = (start, end, other) => {
  let current = dayjs(start);
  const endDate = dayjs(end);
  const workingDays = [];

  while (current.isBefore(endDate) || current.isSame(endDate, "day")) {
    // Проверяем, является ли текущий день рабочим (не выходным)
    if (current.day() !== 0 && current.day() !== 6) {
      // Устанавливаем рабочие часы с 9:00 до 18:00
      const businessStart = current.hour(9).minute(0).second(0);
      const businessEnd = current.hour(18).minute(0).second(0);

      // Добавляем событие на рабочий день
      workingDays.push({
        start: businessStart.toISOString(),
        end: businessEnd.toISOString(),
        ...other,
      });
    }
    current = current.add(1, "day");
  }

  return workingDays;
};

const DashboardCalendar = ({ events }) => {
  const { data: invoices } = useData("/sales/contracts", []);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const { t, i18n } = useTranslation();
  const { language } = i18n;

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event); // Сохраняем выбранное событие
    setIsModalVisible(true); // Открываем модалку
  };

  // Обработчик закрытия модалки
  const handleModalClose = () => {
    setIsModalVisible(false); // Закрываем модалку
    setSelectedEvent(null); // Очищаем выбранное событие
  };

  const invoice = useMemo(
    () => invoices?.find((v) => selectedEvent?.title?.includes(v.id)),
    [invoices, selectedEvent],
  );

  return (
    <div>
      <FullCalendar
        locales={[ru, ka]}
        locale={language}
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
        }}
        initialView="dayGridMonth"
        dayMaxEvents={true} // allow "more" link when too many events
        dayMaxEventRows={5}
        events={
          events
            .map(({ start, end, ...other }) =>
              generateWorkingDays(start, end, other),
            )
            .flat() // Разворачиваем массив массивов в плоский массив событий
        } // alternatively, use the `events` setting to fetch from a feed
        eventClick={handleEventClick}
        timeZone={"local"}
        businessHours={[
          {
            daysOfWeek: [1, 2, 3, 4, 5], // Monday - Thursday
            startTime: "09:00", // a start time (10am in this example)
            endTime: "18:00", // an end time (6pm in this example)
          },
        ]}
      />

      <Modal
        title={selectedEvent ? selectedEvent.title : "Информация о событии"}
        open={isModalVisible}
        onCancel={handleModalClose}
        footer={[
          <Button key="close" onClick={handleModalClose}>
            Закрыть
          </Button>,
        ]}
      >
        {selectedEvent && (
          <ul>
            <li>
              <b>Статус</b>: {t("sales.contractStatus." + invoice.status)}
            </li>
            <li>
              <b>Начало</b>: {dayjs(invoice.created).format("DD MMMM YY")}
            </li>
            <li>
              <b>Конец</b>:{" "}
              {addBusinessDays(invoice.created, invoice.days || 15).format(
                "DD MMMM YY",
              )}
            </li>
          </ul>
        )}
      </Modal>
    </div>
  );
};

export default DashboardCalendar;
