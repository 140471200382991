import React from "react";
import { Route, Routes } from "react-router-dom";
import Header from "../InitialPage/Sidebar/Header";
import Sidebar from "../InitialPage/Sidebar/Sidebar";
import { pagesRoute, publicRoutes } from "./router.link";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "../feature-module/loader/loader";
import RequireAuth from "../feature-module/pages/login/RequireAuth";
import AuthProvider from "react-auth-kit";
import AuthStore from "../Store/auth/auth";
import refreshApi from "../Store/auth/refresh";

const AllRoutes = () => {
  const data = useSelector((state) => state.toggle_header);
  // const info = useAuthUser();
  // const layoutStyles = useSelector((state) => state.layoutstyledata);
  const HeaderLayout = () => (
    <div className={`main-wrapper ${data ? "header-collapse" : ""}`}>
      <Header />
      {/* {layoutStyles == "collapsed" ? (
        <CollapsedSidebar />
      ) : layoutStyles == "horizontal" ? (
        <HorizontalSidebar />
      ) : (
        <Sidebar />
      )} */}
      <Sidebar />
      <Outlet />
      <Loader />
    </div>
  );

  const Authpages = () => (
    <div className={data ? "header-collapse" : ""}>
      <Outlet />
      <Loader />
    </div>
  );

  return (
    <AuthProvider store={AuthStore} refresh={refreshApi}>
      <Routes>
        <Route path={"/"} element={<HeaderLayout />}>
          {publicRoutes.map((route, id) => (
            <Route
              path={route.path}
              element={<RequireAuth>{route.element}</RequireAuth>}
              key={id}
            />
          ))}
        </Route>

        <Route path={"/"} element={<Authpages />}>
          {pagesRoute.map((route, id) => (
            <Route path={route.path} element={route.element} key={id} />
          ))}
        </Route>
      </Routes>
    </AuthProvider>
  );
};
export default AllRoutes;
