import React from "react";
import { Provider } from "react-redux";
import store from "./core/redux/store";
import { BrowserRouter } from "react-router-dom";
import { base_path } from "./environment";
import AllRoutes from "./Router/router";
import AuthStore from "./Store/auth/auth";
import refreshApi from "./Store/auth/refresh";
import AuthProvider from "react-auth-kit";

function App() {
  return (
    <Provider store={{ ...store }}>
      <AuthProvider store={AuthStore} refresh={refreshApi}>
        <BrowserRouter basename={base_path}>
          <AllRoutes />
        </BrowserRouter>
      </AuthProvider>
    </Provider>
  );
}
export default App;
