import React from "react";
import { Link } from "react-router-dom";
import Table from "../../core/pagination/datatable";
import { Image, Select } from "antd";
import useData from "../../hooks/useData";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import NumberFormat from "../../utils/NumberFormat";
import dayjs from "dayjs";
import "dayjs/locale/ka";
import "dayjs/locale/ru";
import "dayjs/locale/en";

const ItemTable = ({ data }) => {
  const {
    i18n: { language },
  } = useTranslation();
  const columns = [
    {
      title: "№",
      dataIndex: "_id",
      width: 50,
      render: (id) => data?.findIndex((v) => v._id === id) + 1,
    },
    {
      title: "Название",
      dataIndex: "name",
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Image.PreviewGroup items={record.images.map((s) => s.src)}>
            <Image
              src={record.images[0].src}
              alt="img"
              className="img-fluid img-40"
              width={30}
              height={30}
              style={{
                background: "#FFF",
              }}
            />
          </Image.PreviewGroup>
          <a
            href={`https://www.presta.ge/search#gsc.tab=0&gsc.q=${JSON.parse(text)[language]}`}
            className="ms-2"
            target={"_blank"}
            rel={"noreferrer"}
          >
            {JSON.parse(text)[language]}
          </a>
        </div>
      ),
    },
    // {
    //   title: "Название",
    //   dataIndex: "name",
    // },
    {
      title: "Цена",
      dataIndex: "price",
      render: (price) => `${NumberFormat(price * 1.2)} ₾`,
    },
    {
      title: "Количество",
      dataIndex: "quantity",
    },
    {
      title: "Сумма",
      dataIndex: "totalPrice",
      render: (text, record) =>
        `${NumberFormat(record.price * record.quantity * 1.2)} ₾`,
    },
    {
      title: "Размеры (ШxВxГ)",
      dataIndex: "dimension",
      render: (dimensions) =>
        `${dimensions?.width}x${dimensions?.height}x${dimensions?.depth}`,
    },
  ];
  return <Table columns={columns} dataSource={data} pagination={false} />;
};

const OnlineSales = () => {
  const {
    data: dataSource,
    remove,
    update,
    isLoading,
    isMutating,
  } = useData("/products/orders", []);

  const {
    i18n: { language },
  } = useTranslation();

  dayjs.locale(language);

  const columns = [
    {
      title: "№",
      dataIndex: "_id",
      width: 50,
      render: (id) => dataSource?.findIndex((v) => v._id === id) + 1,
      sorter: (a, b) => {
        const idA = dataSource?.findIndex((v) => v._id === a._id) + 1;
        const idB = dataSource?.findIndex((v) => v._id === b._id) + 1;
        return idA - idB;
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Данные клиента",
      dataIndex: "userInfo",
      render: (text) => (
        <ul>
          <li>
            <b>Имя:</b> {text.name}
          </li>
          <li>
            <b>Телефон:</b> {text.phone}
          </li>
          <li>
            <b>Адрес:</b> {text.address}
          </li>
        </ul>
      ),
    },
    {
      title: "Дата",
      dataIndex: "date",
      render: (text) => dayjs(text).format("DD MMM, YYYY"),
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      defaultSortOrder: "descend",
    },
    {
      title: "Статус",
      dataIndex: "status",
      render: (text, record) => (
        <Select
          options={[
            { value: "InActive", label: "Не начато" },
            { value: "Active", label: "В Работе" },
            { value: "Rejected", label: "Отменен" },
            { value: "Completed", label: "Завершен" },
          ]}
          onChange={(value) => update({ id: record._id, status: value })}
          defaultValue={text || "InActive"}
        />
      ),
      sorter: (a, b) => a?.status.localeCompare(b?.status),
    },

    {
      title: "Сумма",
      dataIndex: "totalPrice",
      render: (text) => `${NumberFormat(text)} ₾`,
      sorter: (a, b) => a.totalPrice - b.totalPrice,
    },
    {
      title: "Действа",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <td className="action-table-data">
          <div className="edit-delete-action">
            <Link
              className="confirm-text p-2"
              to="#"
              onClick={() => showConfirmationAlert(record._id)}
            >
              <i data-feather="trash-2" className="feather-trash-2"></i>
            </Link>
          </div>
        </td>
      ),
    },
  ];

  const MySwal = withReactContent(Swal);

  const showConfirmationAlert = (id) => {
    MySwal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#00ff00",
      confirmButtonText: "Yes, delete it!",
      cancelButtonColor: "#ff0000",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await remove(id);
        MySwal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          className: "btn btn-success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "btn btn-success",
          },
        });
      } else {
        MySwal.close();
      }
    });
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="add-item d-flex">
            <div className="page-title">
              <h4>Онлайн заказы</h4>
            </div>
          </div>
        </div>
        {/* /product list */}
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={isLoading || isMutating}
            expandable={{
              expandedRowRender: (record) => (
                <ItemTable
                  data={record?.items}
                  id={record._id}
                  key={record._id}
                />
              ),
              rowExpandable: (record) => record.items.length > 0,
            }}
            rowClassName={(record) => {
              if (record.status === "Rejected") {
                return "rejected-row";
              }
              if (record.status === "Completed") {
                return "selected-row";
              }
              return "";
            }}
          />
        </div>
        {/* /product list */}
      </div>
    </div>
  );
};

export default OnlineSales;
