import dayjs from "dayjs";

function addBusinessDays(date, days) {
  let currentDate = dayjs(date);
  let addedDays = 0;

  while (addedDays < days) {
    currentDate = currentDate.add(1, "day");
    // Check if the current day is not Saturday (6) or Sunday (0)
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      addedDays++;
    }
  }

  return currentDate;
}

export default addBusinessDays;
