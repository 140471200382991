import React from "react";
import { Table } from "antd";

const Datatable = ({ props, columns, dataSource, ...other }) => {
  return (
    <Table
      key={props}
      className="table datanew dataTable no-footer"
      columns={columns}
      dataSource={dataSource}
      rowKey={(record) => record.id || record._id}
      pagination={{
        size: "small",
        pageSize: 50,
      }}
      {...other}
    />
  );
};

export default Datatable;
