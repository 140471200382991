import React, { useState } from "react";
import { Avatar, Flex, Button, Menu } from "antd";
import { UserPlus } from "react-feather";
import { OverlayTrigger, Popover } from "react-bootstrap";
import extractName, {
  getInitials,
  getColorFromInitials,
} from "../../utils/extractName";
import "./style.scss";

const SelectUser = ({ selected, options, onChange, multiple, disabled }) => {
  const [selectedValue, setSelectedValue] = useState(selected ? selected : []);
  const getName = (value) => {
    const option = options?.find((option) => option.value === value);
    return option ? getInitials(extractName(option.label)) : "";
  };

  const handleClick = (v) => {
    if (disabled) {
      return;
    }
    setSelectedValue(v.selectedKeys);
    onChange(v.selectedKeys);
  };

  return (
    <Flex gap={32} justify={"center"}>
      <OverlayTrigger
        trigger={["click"]}
        placement={"auto-end"}
        rootClose
        overlay={
          <Popover style={{ width: 300 }}>
            <Popover.Header>
              {selectedValue?.length
                ? `Участвуют ${selectedValue?.length || 0} человек(а)`
                : "Не назначено"}
            </Popover.Header>
            <Popover.Body style={{ padding: 0 }}>
              <Menu
                multiple={multiple}
                items={options?.map((option) => ({
                  key: option.value,
                  icon: (
                    <Avatar
                      size={"small"}
                      style={{
                        backgroundColor: getColorFromInitials(
                          getName(option.value),
                        ),
                        color: "white",
                      }}
                    >
                      {getName(option.value)}
                    </Avatar>
                  ),
                  label: extractName(option.label),
                }))}
                size="small"
                selectedKeys={selectedValue}
                onSelect={handleClick}
                onDeselect={handleClick}
              />
            </Popover.Body>
          </Popover>
        }
      >
        {selectedValue?.length ? (
          <a>
            <Avatar.Group
              maxCount={1}
              maxStyle={{ color: "#f56a00", backgroundColor: "#fde3cf" }}
              size={"small"}
              maxPopoverTrigger={"focus"}
            >
              {selectedValue?.map((value) => {
                const backgroundColor = getColorFromInitials(getName(value));
                return (
                  <Avatar key={value} style={{ backgroundColor }}>
                    {getName(value)}
                  </Avatar>
                );
              })}
            </Avatar.Group>
          </a>
        ) : (
          <Button
            type="primary"
            shape="circle"
            icon={<UserPlus />}
            size={"small"}
          />
        )}
      </OverlayTrigger>
    </Flex>
  );
};

export default SelectUser;
